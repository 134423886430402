.container {
    width:100%;
    height:auto;
    margin-top:60px;
    background-color: #f6f7fe;
    display:flex;
    flex-wrap: wrap;
    padding-bottom:20px;
}

.leftSide {
display:flex;
flex-wrap:wrap;
width:65%;
}
.rightSide {
    display:flex;
    flex-wrap:wrap;
    width:35%;
 }
.debateBox {
    margin-top:20px;
    margin-left:20px;
    width: 650px;
    height:285px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px;
    border-radius:5px;
    position:relative;
    transition: transform .2s;
    background-color: white;
    
 }
    
    
    .header {
    width:100%;
    height:80px;
    margin-top:5px;
    display:flex;
    flex-wrap:wrap;
    background-color:#2f5b82;
    position:relative;
    }
    
    .headerImage {
    width:70px;
    height:70px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px;
    margin-left:10px;
    margin-top:5px;
    }
    
    .headerMain {
     width:560px;
     height:100%;
    
    }
    
    .headerRow {
    width:560px;
    height:50%;
    display:flex;
    align-items: center;
    }
    
    .headerTitle {
    
    font-size:18px;
    margin-left:15px;
    color:white;
    width:400px;
    }
    
    .topicType {
    font-size:10px;
    margin-left:15px;
    color:white;
    white-space:nowrap;
    overflow:hidden;
    }
    
    .pointValue {
    font-size:10px;
    margin-left:15px;
    color:white;
    white-space:nowrap;
    overflow:hidden;
    }
    
    .topicDate {
    font-size:14px;
    text-align: left;
    color:white;
    height:15px;
    margin-left:450px;
    position:absolute;
    }
    
    .timeRemaining {
    font-size:10px;
    text-align: left;
    color:white;
    height:15px;
    position:absolute;
    margin-left:450px;
    }
    
    
    .topicDescription {
    font-size:14px;
    margin-left:15px;
    text-align: left;
    margin-top:20px;
    margin-right:15px;
    }
    
    .footer {
    width:100%;
    height:40px;
    position:absolute;
    bottom:10px;
    left:0%;
    display:flex;
    align-items:center;
    }
    
    .answers {
    font-size:12px;
    font-weight:800px;
    margin-left:15px;
    white-space:nowrap;
    overflow:hidden;
    }
    
    .votes {
    font-size:12px;
    font-weight:800px;
    margin-left:15px;
    white-space:nowrap;
    overflow:hidden;
    }
    
    .joinButton {
    font-size:14px;
    font-weight:800px;
    margin-left:15px;
    color:#0079e3;
    position:absolute;
    right:20px;
    cursor:pointer;
    }
    
    .joinButton:hover{
    color:#85c6ff;  
    }

    .answersBox {
        margin-top:20px;
        margin-left:20px;
        width: 650px;
        height:auto;
        min-height:400px;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px;
        border-radius:5px;
        position:relative;
        transition: transform .2s;
        background-color: white;
    }

    .answersHeader {
    width:100%;
    background-color:#2f5b82;
    height:50px;
    position:absolute;
    top:0;
    }
    .answersHeaderTitle {
        margin-left:20px;
        font-size: 18px;
        color:white
    }

    .answersContainer {
        width:100%;
        height:auto;
        display:flex;
        flex-wrap:wrap;
        margin-top:50px;
    }

    .rectButton {
        width:140px;
        height:30px;
        border-radius:2px;
        font-size:14px;
        font-weight:bold;
        margin-left:15px;
        background-color:#0079e3;
        cursor:pointer;
        line-height: 30px;
        text-align: center;
        color:white;
        position:absolute;
        right:20px;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px;
        }
        
    .rectButton:hover{
        background-color:#85c6ff;  
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 5px;
     }
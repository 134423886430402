.container {
margin-top:20px;
width: 650px;
height:275px;
box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px;
border-radius:5px;
position:relative;
transition: transform .2s;
background-color:white;
}

.container:hover {
transform: scale(1.015);
box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 5px;
}

.header {
width:100%;
height:80px;
margin-top:5px;
display:flex;
flex-wrap:wrap;
background-color:#2f5b82;
position:relative;
}

.headerImage {
width:70px;
height:70px;
box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px;
margin-left:10px;
margin-top:5px;
}

.headerMain {
 width:560px;
 height:100%;

}

.headerRow {
width:560px;
height:50%;
display:flex;
align-items: center;
}

.headerTitle {

font-size:18px;
margin-left:15px;
color:white;
width:400px;
}

.topicType {
font-size:10px;
margin-left:15px;
color:white;
white-space:nowrap;
overflow:hidden;
}

.pointValue {
font-size:10px;
margin-left:15px;
color:white;
white-space:nowrap;
overflow:hidden;
}

.topicDate {
font-size:14px;
text-align: left;
color:white;
height:15px;
margin-left:450px;
position:absolute;
}

.timeRemaining {
font-size:10px;
text-align: left;
color:white;
height:15px;
position:absolute;
margin-left:450px;
}


.topicDescription {
font-size:14px;
margin-left:15px;
text-align: left;
margin-top:20px;
margin-right:15px;
}

.footer {
width:100%;
height:40px;
position:absolute;
bottom:0;
left:0%;
display:flex;
align-items:center;
}

.answers {
font-size:12px;
font-weight:800px;
margin-left:15px;
white-space:nowrap;
overflow:hidden;
}

.votes {
font-size:12px;
font-weight:800px;
margin-left:15px;
white-space:nowrap;
overflow:hidden;
}

.joinButton {
font-size:14px;
font-weight:800px;
margin-left:15px;
color:#0079e3;
position:absolute;
right:20px;
cursor:pointer;
}

.joinButton:hover{
color:#85c6ff;  
}
.container {
    width:100%;
    height:60px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px;
    background-color:white;
    position:fixed;
    z-index:5;
    display:flex;
    align-items: center;
}


.logoCenter {
    width:50px;
    height:50px;
    margin:0 auto;
    cursor: pointer;
}
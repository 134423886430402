.container {
width:100%;
height:280px;
margin-top:20px;
background-color: white;
box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px;
border-radius:2px;
display: inline-block;
}

.headerTitle {

    font-size:18px;
    margin-left:15px;
    margin-top:15px;
    width:100%;
}

.leaderList {
    margin-top: 30px;
    width:100%;
    height:80%;
    
    
}

.row {
    margin-left:15px;
    width:90%;
    display:flex;
    align-items: center;
    justify-content: space-between;
    margin-top:5px;

}
.leaderName {

    font-size:14px;
    margin-top:0;
    
    
}

.leaderPoints {

    font-size:12px;
    margin-top: 0;
    
}
.container {
width:100%;
height:175px;
margin-top:20px;
background-color: white;
box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px;
border-radius:2px;
display:inline-block;
}

.headerTitle {

    font-size:18px;
    margin-left:15px;
    margin-top:15px;
    width:100%;
    }

.barContainer {
    width:85%;
    height:14px;
    margin:0 auto;
    border-radius: 20px;
    background-color: rgba(170, 170, 170, 0.9);
    margin-top:10px;
    position:relative;

}

.barValue {
    width:60%;
    height:14px;
    border-radius: 20px;
    background-color: rgba(94, 97, 255, 1);
}

.barNumbers {
    width:100%;
    line-height:14px;
    font-size: 12px;
    text-align: center;
    position:absolute;
    top:0;
    left:0;
}

.rankTitle {
    font-size:16px;
    margin-top:15px;
    width:100%;
    text-align: center;
}
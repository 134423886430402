.container {
 width:100%;
 height:auto;
 margin-top:15px;
 padding-bottom:15px;
 background-color:white;
 border-bottom:1px solid rgba(219, 219, 219, 0.9);
 display:flex;
 flex-wrap:wrap;
}

.header {
    width:100%;
    height:50px;
    display:flex;
    align-items:center;
    position:relative;
    
}

.username {
 text-align: center;
 font-size:10px;
 font-weight: 900px;
 width:120px;
 margin-left:20px;
 overflow-x:hidden;
}

.rightHeaderContainer {

position:absolute;
right:20px;
height:50px;
width:auto;
display:flex;
flex-wrap:wrap;
align-items:center;
}

.agreeButton {
    font-size:14px;
    font-weight:800px;
    margin-left:15px;
    color:#0079e3;
    cursor:pointer;
    }
    
.agreeButton:hover{
    color:#85c6ff;  
 }

 .rectButton {
    width:95px;
    height:30px;
    border-radius:2px;
    font-size:14px;
    font-weight:bold;
    margin-left:15px;
    background-color:#0079e3;
    cursor:pointer;
    line-height: 30px;
    text-align: center;
    color:white;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px;
        }
        
    .rectButton:hover{
        background-color:#85c6ff;  
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 5px;
     }

.timestamp {
    text-align: center;
    font-size:10px;
    font-weight: 900px;
    margin-left:7%;
   }

.badgeContainer {
    width:120px;
    height:50px;
    display:flex;
    justify-content: center;
    margin-left:20px;
}
.userBadge {
width:35px;
height:35px;
margin-top:10px;

}

.contentBox {
display:flex;
width:68%;
margin-left:7%;
height:auto;
margin-top:10px;

}

.contentLongAnswer {
font-size:12px;

}
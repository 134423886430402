.container {
    width:100%;
    height:100%;
    background-color: rgba(124, 125, 125, 0.85);
    position:fixed;
    top:0;
    left:0;
    display:flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.content {
 width:900px;
 height:450px;
 border-radius:2px;
 background-color: white;
 position:relative;
}

.closeButton {
    width:40px;
    height:40px;
    line-height: 40px;
    font-size: 30px;
    font-weight: bold;
    color:rgba(173, 173, 173, 0.9);
    text-align: center;
    position: absolute;
    right:20px;
    top:20px;
    cursor:pointer;
    background-color:rgba(230, 230, 230, 0.7);
    border-radius:50%;
}

.closeButton:hover {
    color:rgba(140, 140, 140, 0.9);
    background-color:rgba(210, 210, 210, 0.7);
}

.title {
    font-size:22px;
    line-height:40px;
    font-weight:bold;
    margin-left:20px;
    margin-top:20px;
    position:absolute;
}

